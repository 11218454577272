import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://505a1750726251619267e5705a4f5f94@o4508235332911104.ingest.us.sentry.io/4508235344904192",
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
    Sentry.browserTracingIntegration({
      tracePropagationTargets: [/\//, "looneyloop.com"],
    }),
  ],
  tracesSampleRate: 1.0,
});
